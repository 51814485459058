import React from "react"
import { Link } from "gatsby"
import PageLayout from "../PageLayout"
import { PageMargin, PageTitle, Button } from "../GlobalStyles"
import styled from "styled-components/macro"

const ContactBtn = styled(Button)`
  display: inline-flex;
`

const NotFound = () => {
  return (
    <PageLayout>
      <PageMargin>
        <PageTitle>Page not found</PageTitle>
        <p>You seem to have browsed to a page that does not exist</p>
        <p>
          Feel free to follow the links in the website header to browse to
          various site content.
        </p>
        <ContactBtn as={Link} to="/contact">
          Contact Us
        </ContactBtn>
      </PageMargin>
    </PageLayout>
  )
}

export default NotFound
